import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'

const Name = styled.h1`
  font-size: 10vw;
  padding: 0;
  margin: 0;
  color: blue;
`

const IndexPage = () => (
  <Layout>
    <Name>SPACEWALK<br />LABS</Name>
  </Layout>
)

export default IndexPage
